import React, { useState } from "react";
import { Link } from "gatsby";
import Logo from "../images/svg/cnh-logo.svg";

const navStyles = "flex justify-between px-4 md:px-8 py-4";
const navComponentStyles = "flex justify-between items-center w-full md:w-auto";

const Header = ({renderTrialClass}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="">
      <nav className={navStyles}>
        <div className={navComponentStyles}>
          <Link to="/">
            <Logo />
          </Link>
          <div class="-mr-2 flex items-center md:hidden">
            {!menuOpen && (
              <button
                onClick={() => setMenuOpen(true)}
                type="button"
                class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                id="main-menu"
                aria-label="Main menu"
                aria-haspopup="true"
              >
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />{" "}
                </svg>
              </button>
            )}
            {menuOpen && (
              <button
                onClick={() => setMenuOpen(false)}
                type="button"
                class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                aria-label="Close menu"
              >
                {" "}
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />{" "}
                </svg>{" "}
              </button>
            )}
          </div>
        </div>
        <div class="hidden md:flex items-center space-x-12">
          <div class="relative group hover">
            <span
              class="font-medium text-cnh-script-300 leading-10 group-hover:text-cnh-script-500 transition duration-150 ease-in-out"
            >
              Courses
            </span>
            <div class="hidden group-hover:block absolute right-0 -ml-4 bg-cnh-functional-200 rounded-md w-56 shadow-md overflow-hidden py-1 z-50">
              <Link to="/courses/learn-scratch" className="block text-sm px-4 py-2 leading-5 hover:bg-gray-200">
                Scratch
              </Link>
              <Link to="/courses/learn-python" className="block text-sm px-4 py-2 leading-5 hover:bg-gray-200">
                Python
              </Link>
              <Link to="/courses/learn-web-development" className="block text-sm px-4 py-2 leading-5 hover:bg-gray-200">
                Web Development
              </Link>
            </div>
          </div>
          {/* <a
            class="font-medium text-cnh-script-300 hover:text-cnh-script-500 transition duration-150 ease-in-out"
            href=""
          >
            About
          </a> */}
          {renderTrialClass &&  <div className="rounded-lg bg-cnh-accent-flamingo w-32 p-2 md:p-3 hover:bg-cnh-script-500 transition duration-150 ease-in-out text-center">
              <Link to='/apply' className="text-base text-white" >Get Started</Link>
               </div>}
        </div>
      </nav>
      <div class="relative h-0">
        {menuOpen && (
          <div
            class="absolute z-50 top-0 left-0 right-0 px-2 md:hidden"
            onClick={() => setMenuOpen(false)}
          >
            <div class="rounded-b-lg shadow-md overflow-hidden">
              <div
                class="bg-cnh-functional-200 shadow-xs overflow-hidden"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="main-menu"
              >
                <div class="">
                  <div class="pb-3">
                    <Link to="/courses/learn-scratch" className="mt-1 block px-3 py-2 text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" >
                      Scratch
                    </Link>
                    <Link to="/courses/learn-web-development" className="mt-1 block px-3 py-2 text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" >
                      Web Development
                    </Link>
                    <Link to="/courses/learn-python" className="mt-1 block px-3 py-2 text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" >
                      Python
                    </Link>
                    {/* <Link to="/about" className="mt-1 block px-3 py-2 text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" >
                      About
                    </Link> */}
                    {renderTrialClass && <Link to="/apply" className="block w-full px-5 py-3 text-center font-medium text-white bg-cnh-accent-flamingo hover:bg-cnh-script-500 focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out">
                      Trial Class
                    </Link>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
