import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './Header';
import Footer from './Footer';

const Layout = ({renderTrialClass = true, children}) => {

  const data = useStaticQuery(graphql`
    {
      site {
        id
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <main className="min-h-screen flex flex-col space-y-0">
      <Header className="relative" renderTrialClass={renderTrialClass} />
      <div className="flex-auto">{children}</div>
      <Footer />
    </main>
    // </>
  )
}

export default Layout