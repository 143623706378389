import React from 'react';
import facebookLogo from '../images/facebook-logo.png'
import facebookLogo2x from '../images/facebook-logo@2x.png'
import facebookLogo3x from '../images/facebook-logo@3x.png'
import twitterLogo from '../images/svg/twitter-logo.svg'
import instagramLogo from '../images/instagram-logo.png'
import instagramLogo2x from '../images/instagram-logo@2x.png'
import instagramLogo3x from '../images/instagram-logo@3x.png'

const facebookLogoSrcSet = ''

const Footer = () => {
  return (
    <footer className="py-16 md:py-24 bg-cnh-brand-primary">
      <div className="w-full md:w-1/3 mx-auto text-center mb-2">
          <div class="w-auto inline-block mx-auto"> 
          <img class="inline-block w-4 mr-3"
            srcset ={`${facebookLogo3x} 3x, ${facebookLogo2x} 2x, ${facebookLogo} 1x`}
            src={facebookLogo} alt="" /> 
          <img class="inline-block w-4 mr-3"
            src={twitterLogo} alt="" /> 
          <a href="https://www.instagram.com/codenhour/" target="_blank">
          <img class="inline-block w-4"
            srcset ={`${instagramLogo3x} 3x, ${instagramLogo2x} 2x, ${instagramLogo} 1x`}
            src={instagramLogo} alt="" /> 
          </a>
          </div>
          
          <p className="text-sm text-cnh-functional-400 pt-6">Copyright © 2022 Code N Hour LLC. All rights reserved. 
          <a href="/docs/cnh_privacy_policy.pdf" target="_blank" className="text-cnh-functional-200 underline hover:text-cnh-accent-kournikova pl-1">Privacy</a>, 
          <a href="/docs/cnh_terms_of_user.pdf" target="_blank" className="text-cnh-functional-200 underline hover:text-cnh-accent-kournikova pl-1">Terms</a>
          </p>
      </div>
    </footer>
  )
}

export default Footer;